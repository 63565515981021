import * as React from 'react';
import * as styles from './index.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Brand from '../components/brand';
import Button from '../components/button';
import EmbedButton from '../components/embed-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';
import WistiaEmbed from '../components/utils/wistia-embed';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { graphql } from 'gatsby';

const IndexPage = ({ data }) => {
  const quizImage = getImage(data.quizImage);

  return (
    <>
      <SEO title="The AI-Powered Quiz Platform" />
      <Header btnLabel="Book a demo" btnLink="/demo/" />
      <main className={styles.index}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `6rem` }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 col-xl-9 text-center">
              <h1 className="color-black" style={{ marginBottom: `2.5rem` }}>
                Make a quiz for your business in <span>1 minute</span>
              </h1>
            </div>
            <div className="col-md-10 col-lg-8 col-xl-6">
              <h2 className="text-body color-muted-foreground text-center">
                Enter your website URL to create a quiz for your brand.
              </h2>
              <form method="get" action="https://ai.tryinteract.com/create">
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    placeholder="https://www.mysite.com"
                    name="website_url"></input>
                  <Button theme="primary" type="submit" size="large" style={{ gap: '8px' }}>
                    <FontAwesomeIcon icon={faSparkles} /> Generate quiz
                  </Button>
                </div>
              </form>
              <p className={styles.subtext}>
                Already know what you want? <Link to="/plans/">Start from scratch</Link>
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: '6rem' }}>
            <div className="col-lg-12">
              <div className={styles.videoContainer}>
                <WistiaEmbed hashedId="a0b08gfysm" playerColor="#7371fc" />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Empowering businesses since 2014 with proven results:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>1.2B</p>
              <p className={styles.label}>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>400M</p>
              <p className={styles.label}>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>85M</p>
              <p className={styles.label}>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1 text-md-center">
              <div className={styles.quizContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Take Quiz
                </p>
                <p
                  className="heading-lg color-primary-950 font-style-golos"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  What quiz should you make for your business?
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Find your perfect lead generation quiz. Unlock the power of a 40% conversion rate
                  that quizzes offer by finding the exact quiz type that will work for your
                  business.
                </p>
                <EmbedButton id="65414c9de4c0cb0014c537e5" disableCover={true}>
                  Find out now
                </EmbedButton>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <GatsbyImage image={quizImage} alt="Interact template screenshots" />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">What quizzes can do for your business</h3>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-stretch"
            style={{ marginTop: '3rem', rowGap: '1.5rem' }}>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Lead magnet</p>
                <p className="heading-lg">Convert traffic into leads</p>
                <p className="heading-sm">
                  Engage your website visitors with a quiz that appeals to exactly what is on their
                  minds when they come to your site. Then convert your visitors into leads through
                  the quiz experience.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">E-Commerce</p>
                <p className="heading-lg">Recommend products and services</p>
                <p className="heading-sm">
                  Personalize your product and service lineup for every potential customer and
                  recommend exactly the right offer for them based on their wants and needs.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Segmentation</p>
                <p className="heading-lg">Assess your customers and recommend a starting point</p>
                <p className="heading-sm">
                  Identify the needs of your customers based on an assessment so they can see
                  clearly how to begin working with your business.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.feature}>
                <p className="heading-xs-upper">Customer support</p>
                <p className="heading-lg">Answer customer questions</p>
                <p className="heading-sm">
                  Be there for every customer with a unique answer to their complex questions you
                  would otherwise have to have a human answer in real time, 24/7.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-auto d-none d-sm-block">
              <div className={styles.integrations}>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Klaviyo"
                    style={{ backgroundPosition: '0px -880px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Zapier"
                    style={{ backgroundPosition: '0px -1720px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Mailchimp"
                    style={{ backgroundPosition: '0px -960px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Interact"
                    style={{ backgroundPosition: '0px -760px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Convertkit"
                    style={{ backgroundPosition: '0px -240px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Flodesk"
                    style={{ backgroundPosition: '0px -480px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Constant Contact"
                    style={{ backgroundPosition: '0px -200px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Integrate quizzes with your existing tools</h3>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm">
                Identify the needs of your customers based on an assessment so they can see clearly
                how to begin working with your business.
              </p>
              <Button
                theme="outline"
                size="large"
                type="internal"
                href="/integrations"
                style={{ marginTop: '2rem' }}>
                Browse integrations
              </Button>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our customers love Interact quizzes</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  THANK YOU! This has literally been on my list for years and this is going to make
                  it so easy to just get it out there.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kelsey K.</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">Oh my goodness this is good!</p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Heather F.</p>
                  <p className="text-body">Marketing Agency</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Such a clean design, heaps of integration options that I think my sutdents will
                  benefit the most from using Interact over competitors.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Teago</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  This is so much fun, and so easy and intuitive.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kemya S.</p>
                  <p className="text-body">Digital Marketing</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I found it really helpful in getting a head start on the quiz and felt like it
                  saved me a lot of time from having to manually build it from scratch.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Lauren T.</p>
                  <p className="text-body">Interior Designer</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I love it, and I am excited about using it for my client work as well as my lead
                  generation.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Marie P.</p>
                  <p className="text-body">Entrepreneur</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Creating personalized quizzes to generate leads for my offers was surprisngly
                  easy.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Connor H.</p>
                  <p className="text-body">Ditigal Creator</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I just made a quiz in 5 minutes. It's pretty cool. And I had zero ideas of what I
                  wanted going into it.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Emily</p>
                  <p className="text-body">Travel</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I am so impressed with this quiz! It really feels like you captured my brand...
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Christina C.</p>
                  <p className="text-body">Life Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-white">Get started today</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Use our AI quiz maker to create a beautiful quiz for your brand in a minute.
              </p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">
                Make a quiz - for free
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    quizImage: file(relativePath: { eq: "landing-quiz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
      }
    }
  }
`;
